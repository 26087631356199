exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aplicaciones-web-js": () => import("./../../../src/pages/AplicacionesWeb.js" /* webpackChunkName: "component---src-pages-aplicaciones-web-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/Contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/Marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-moviles-js": () => import("./../../../src/pages/Moviles.js" /* webpackChunkName: "component---src-pages-moviles-js" */),
  "component---src-pages-politica-js": () => import("./../../../src/pages/Politica.js" /* webpackChunkName: "component---src-pages-politica-js" */),
  "component---src-pages-preguntas-js": () => import("./../../../src/pages/Preguntas.js" /* webpackChunkName: "component---src-pages-preguntas-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/QuienesSomos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-reinge-js": () => import("./../../../src/pages/Reinge.js" /* webpackChunkName: "component---src-pages-reinge-js" */),
  "component---src-pages-seguridad-js": () => import("./../../../src/pages/Seguridad.js" /* webpackChunkName: "component---src-pages-seguridad-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/Servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-sitios-web-js": () => import("./../../../src/pages/SitiosWeb.js" /* webpackChunkName: "component---src-pages-sitios-web-js" */)
}

